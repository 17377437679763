.products-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 100%;
    padding: 50px 0;
    background-color: #fff;
}

@media screen and (max-width:640px) {
    .products-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

.products-item {
    height: 100%;
    width: 100%;
    color: black;
    text-align: center;
    cursor: pointer;
}

.products-item .productImage {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.products-item .productText {
    padding-top: 10px;
    font-family: 'PFMonumentaPro',sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.products-item.biancoSemi .productImage {
    background-image: url('../assets/images/products/BiancoSemi.jpeg');
}

.products-item.cabernet .productImage {
    background-image: url('../assets/images/products/Cabernet.jpeg');
}

.products-item.chardonay .productImage {
    background-image: url('../assets/images/products/Chardonay.jpeg');
}

.products-item.fragolinoBianco .productImage {
    background-image: url('../assets/images/products/FragolinoBianco.jpg');
}

.products-item.fragolinoRosso .productImage {
    background-image: url('../assets/images/products/FragolinoRosso.jpg');
}

.products-item.lambrusco .productImage {
    background-image: url('../assets/images/products/Lambrusco.jpeg');
}

.products-item.merlotRosato .productImage {
    background-image: url('../assets/images/products/MerlotRosato.jpeg');
}

.products-item.merlotRosso .productImage {
    background-image: url('../assets/images/products/MerlotRosso.jpeg');
}

.products-item.nero .productImage {
    background-image: url('../assets/images/products/Nero.jpeg');
}

.products-item.pinotGrigio .productImage {
    background-image: url('../assets/images/products/PinotGrigio.jpeg');
}

.products-item.rossoSemi .productImage {
    background-image: url('../assets/images/products/RossoSemi.jpeg');
}