/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/

@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Regular.woff2') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face { 
  font-family: "PFMonumentaPro"; 
  src: url('../fonts/PFMonumentaPro-Regular.woff2') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v25-cyrillic_latin-regular.woff2') format('woff2');
}